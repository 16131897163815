const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}`
    : null
}

export const theme = ({ primaryColor, secondaryColor }) => {
  const primary = hexToRgb(primaryColor)
  const secondary = hexToRgb(secondaryColor)

  return {
    colors: {
      primary: `rgb(${primary})`,
      primaryLight: `rgba(${primary}, 0.3)`,
      primaryOpac: `rgba(${primary}, 0.95)`,
      secondary: `rgb(${secondary})`,
      secondaryOpac: `rgba(${secondary}, 0.8)`,
      black: "#000",
      blackOpac: "rgba(0, 0, 0, 0.3)",
      white: "#fff",
      whiteOpac: "rgba(255, 255, 255, 0.95)",
      success: "#03a678",
      errors: "#de4444",
      lightGrey: "#ccc",
      mediumGrey: "#BCBCBC",
      darkGrey: "#666",
      lightBorder: "rgba(34,34,34,0.3)"
    }
  }
}
