/* eslint jsx-a11y/html-has-lang: 0 */
import React from "react"
import styled, { keyframes } from "styled-components"
import { Helmet } from "react-helmet"

const styledKeyframes = keyframes`
  0%, 100% {
    transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
  }
`

const Loader = styled.div`
  width: 5rem;
  height: 5rem;
  position: relative;
  margin: 0 auto;
  border: 0.1rem solid white;
  border-radius: 5rem;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: ${(p) => (p.isLoading ? "opacity 0.3s" : "all 0.3s")};
  z-index: ${(p) => (p.isLoading ? 10002 : -1)};
  opacity: ${(p) => (p.isLoading ? 1 : 0)};
`

const Circle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(p) => p.color || p.theme.colors.white};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${styledKeyframes} 2s infinite ease-in-out;
`

const CircleTwo = styled(Circle)`
  animation-delay: -1s;
`

export const Loading = ({ color, ...other }) => {
  return (
    <Loader {...other}>
      <Circle color={color} />
      <CircleTwo color={color} />
    </Loader>
  )
}

export default ({ color, isLoading, ...other }) => (
  <Overlay isLoading={isLoading}>
    {isLoading && (
      <Helmet>
        <html className="disableScroll" />
        <body className="disableScroll" />
      </Helmet>
    )}
    <Loading color={color} {...other} />
  </Overlay>
)
